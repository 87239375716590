.success-snack-notification {

    .mdc-snackbar__surface {
        background-color: green !important;
    }
}

.error-snack-notification {

    .mdc-snackbar__surface {
        background-color: rgb(255, 8, 0) !important;
    }
}

.warning-snack-notification {

    .mdc-snackbar__surface {
        background-color: rgb(255, 217, 0) !important;

        .mdc-snackbar__label {
            color: black !important;
        }
    }
}

.snack-bar-content {
    .header {
        display: flex;
        align-items: center;
        gap: 10px;

        .title{
            font-weight: 500;
        }

        .close-button {
            margin: auto;
            margin-right: 0px;
        }
    }
}